import { useEffect, useState } from "react";
import Home from "./Components/home";
import HomeMBL from "./Components/homembl";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacy from "./Components/privacy";
import Terms from "./Components/terms";

function App() {
  // const [flag, setflag] = useState(false);
  // useEffect(() => (window.innerWidth < 786 ? setflag(true) : ""), []);
  // window.onresize = (event) => {
  //   if (window.innerWidth > 786 && flag) {
  //     window.location.href = window.location.href;
  //     setflag(false);
  //   }
  //   if (window.innerWidth < 786 && !flag) {
  //     window.location.href = window.location.href;
  //     setflag(true);
  //   }
  // };
  // if (window.innerWidth > 768) return <Home />;
  // return <HomeMBL />;
  // return (
  // <>
  {
    /* <div className="d-none d-md-block"> */
  }
  {
    /* <Home /> */
  }
  {
    /* </div> */
  }
  {
    /* <div className="d-md-none"> */
  }
  {
    /* <HomeMBL /> */
  }
  {
    /* </div> */
  }
  // </>
  // );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={window.innerWidth > 768 ? <Home /> : <HomeMBL />}
        />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<Terms />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
