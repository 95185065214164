import "../App.css";
import arrow from "../images/arrow.png";
import arrowright from "../images/arrow right.png";
import Q from "../images/Q.png";
import weapon from "../images/weapon.png";
import hairstyle_img from "../images/hairstyle.png";
import expression_btn from "../images/expression.png";
import outfit_btn from "../images/outfit.png";
import headwear_btn from "../images/headwear.png";
import eyewear_btn from "../images/eyewear.png";
import brick from "../images/brick desktop.png";
import dots from "../images/dots.png";
import dots2 from "../images/dots2.png";
import downside from "../images/downside.png";
import ticket from "../images/ticket.png";
import couch from "../images/couch.png";
import scroll from "../images/scroll.png";
import chest from "../images/chest.png";
import upside from "../images/upside.png";
import tunnel from "../images/tunnel.png";
import fish from "../images/fishmbl.png";
import ax from "../images/axmbl.png";
import vil from "../images/vilmbl.png";
import dran from "../images/dronmbl.png";
import dronbig from "../images/dronbig.png";
import logo from "../images/logo.png";
import insta from "../images/insta.png";
import twitter from "../images/twitter.png";
import discord from "../images/discord.png";
import enter from "../images/enter.png";
import banner from "../images/banner.mp4";

import dronbigmbl from "../images/dronbigmbl.png";
import fishbigmbl from "../images/fishbigmbl.png";
import axbigmbl from "../images/axbigmbl.png";
import vilbigmbl from "../images/vilbigmbl.png";

import { useEffect, useRef, useState } from "react";

import Slider from "react-slick";

export default function Home() {
  const outfits = ["greek dress-all", "red haori-all"];
  const hairstyles = [1, 2];
  const expressions = ["contempt-male-blue", "relaxed"];
  const handhelds = ["beer-all", "fire sword-all"];
  const eyewears = ["null", "sunglasses"];
  const headwears = ["null", "devil horns-all", "wreath"];

  const [skin, setSkin] = useState("yellow");
  const [outfit, setOutfit] = useState(1);
  const [hairstyle, setHairstyle] = useState(1);
  const [expression, setexpressions] = useState(1);
  const [handheld, sethandheld] = useState(0);
  const [eyewear, seteyewear] = useState(0);
  const [headwear, setheadwear] = useState(0);
  const [hide, sethide] = useState(false);
  const [right, setright] = useState(true);
  const [left, setleft] = useState(false);
  const [avatarBig, setAvatarBig] = useState({
    image: dronbigmbl,
    name: "Dron",
    title: "Playmaker",
    description:
      "Lazy af, can tell by the name. Been traveling between the technology and finance sectors. Sprinkling the precise mix of these two magic powders for the best potion.",
  });

  function updateAvatar(trait, traits, setTrait) {
    if (trait + 1 == traits.length) setTrait(0);
    else setTrait(trait + 1);
  }

  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (i) => {
      if (i == 3) {
        setright(false);
      } else if (i == 0) {
        setleft(false);
      } else {
        setleft(true);
        setright(true);
      }
    },
  };

  const header = (e) => {
    if (document.documentElement.scrollTop < 300) sethide(false);
    else {
      if (e.deltaY > 10) sethide(true);
      else if (e.deltaY < 0) sethide(false);
    }
  };
  const header2 = (e) => {
    if (document.documentElement.scrollTop < 300) sethide(false);
    else {
      if (e.clientY > 150) sethide(true);
      else if (e.clientY < 80) sethide(false);
    }
  };
  return (
    <div
      className="main"
      onWheel={(event) => header(event)}
      onMouseMove={(event) => header2(event)}
    >
      <div id="home" className="container-fluid herobg">
        <div
          className="row justify-content-center align-items-center"
          style={{ position: "relative" }}
        >
          <video
            src={banner}
            autoPlay
            playsInline
            muted
            loop
            className="bannervideo p-0"
          ></video>
          <nav
            className={`navbar navmbl navbar-dark navbar-expand-md bg-transparent ${
              hide ? "nav-up" : ""
            }`}
          >
            <div className="container-fluid p-0">
              <a className="navbar-brand" href="#">
                <img src={logo} style={{ width: "65px" }} />
              </a>
              <button
                className="navbar-toggler collapsed position-relative"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                id="toggle"
              >
                <span> </span>
                <span> </span>
                <span> </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  className="menubar"
                ></div>
                <ul className="navbar-nav navbar-navmbl ms-auto pt-3 align-items-center">
                  <li className="nav-item">
                    <a className="nav-link" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#avatars">
                      Avatars
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#cityhall">
                      City Hall
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#blueprint">
                      Blueprint
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#team">
                      Team
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#faq">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/DSUPOfficial/" target="_blank">
                      <i
                        className="fab fa-twitter ms-auto px-lg-3 px-1 px-md-2 text-white"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </a>
                    <a
                      href="https://www.instagram.com/dsupofficial/"
                      target="_blank"
                    >
                      <i
                        className="fab fa-instagram ms-auto px-lg-3 px-1 px-md-2 text-white"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </a>
                    {/*<a href="https://discord.gg/ZgqJu8UnsZ" target="_blank">
                      <i
                        className="fab fa-discord ms-auto px-lg-3 px-1 px-md-2 text-white"
                        style={{ fontSize: "20px" }}
                      ></i>
          </a>*/}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="col-12 text-center">
            <h1 className="head1" style={{ fontSize: "28px" }}>
              from here to there...
            </h1>
          </div>
        </div>
      </div>

      <div id="about" className="container-fluid aboutbg">
        <div className="container">
          <div
            className="row full align-items-cente"
            style={{ paddingTop: "150px" }}
          >
            <div className="col-md-5"></div>
            <div className="col-md-7 col-lg-5">
              <h1 className="head1 headingD">About</h1>
              <p className="text textD pe-lg-5">
                DSUP - Downside Up - is a revolutionary brand that we are building together.
                <br />
                <br />
                Our journey starts in the digital realm and extends to disrupt the physical world. 
                We aim to harness the power of intangible assets, constructing and creating to 
                deliver true value and unprecedented excitement.
                <br />
                <br />
                At the heart of DSUP lies a gateway between the digital and physical worlds 
                - a collection of 10,101 pixel avatars, each a unique and distinct entrance to 
                the tunnel that connects the two realms.
                <br />
                <br />
                By owning one of these avatars, you gain access to a range of exclusive holders-only 
                benefits, including private club membership, live events, and voting rights to help 
                us achieve decentralization. Along the way, we will continue to reveal and realize 
                groundbreaking benefits, as we work together to build a better future.
              </p>
            </div>
          </div>
          <div className="space"></div>
          <div className="row full align-ite ms-center">
            <div className="col-12 m-0" style={{ paddingBottom: "116px" }}>
              <div className="row pt- mt-">
                <div className="col-lg-2"></div>
                <div className="col-md-6 col-lg-5 ps-lg-5">
                  <h1 className="head1 headingD">Values</h1>
                  <p className="text textD">
                    Our values serve as a compass, guiding us as we respond to ever-changing 
                    circumstances and chart a course for development in our village. They 
                    provide a foundation for us to grow with purpose and vigour in the 
                    exciting new landscape of Web3.
                  </p>
                </div>
              </div>
              <div className="row py-5">
                <div className="col-lg-2"></div>
                <div className="col-12 col-sm-7 col-lg-3 ps-lg-5">
                  <h1 className="head2">Community</h1>
                  <p className="text textD">
                    You are our top priority. We take care of what you need and
                    think out of what you want.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-6 col-md-4 col-lg-3 ps-lg-5">
                  <h1 className="head2">Impact</h1>
                  <p className="text textD">
                    Impact is what DSUP makes. Every step counts. We make and
                    only make the most efficient move and give out the most
                    powerful punch.
                  </p>
                </div>
                <div className="col-md-1 d-none d-md-block"></div>
                <div className="col-12 d-none d-xlc-block"></div>
                <div className="col-2 d-none d-xlc-block"></div>
                <div className="col-6 col-md-4 col-lg-3 ps-xl-5 py-xlc-5">
                  <h1 className="head2">Innovation </h1>
                  <p className="text textD">
                    We amaze the community with continuous innovations. Your
                    astonishments are our greatest motivation to go beyond and
                    bring in new and original stuff.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="mt-n5 d-block" id="avatars"></span>
      <div id="avatar" className="container avatarbg">
        <div className="row pt-2 flex-md-row-reverse justify-content-end align-items-end">
          <div
            className="col-md-7 col-lg-6 col-xl-5 pe-5 ps-5 m s-5 buttons"
            // style={{
            //   paddingBottom: "135px",
            //   top: "-60px",
            //   position: "relative",
            // }}
          >
            <h1 className="head1 headingD">Pixel Avatars</h1>
            <p className="text textD pe-4">
              Each avatar is a unique creation, just like each one of you. 
              <br />
              <br />
              Hailing from diverse corners of the globe, they converge in our peaceful 
              village, a place where countless possibilities are born. It's not uncommon 
              to spot villagers donning a range of outfits and toting both big and small 
              items, reflecting their multifaceted natures. Despite their differences, 
              they share many common qualities - they're cool, laid-back, and full of life.
              <br />
              <br />
              Unofficial estimates suggest that there may be up to four different species 
              of villagers. However, one of these groups is exceedingly rare and their 
              habits remain shrouded in mystery. We would love to hear from you if you have 
              any information about their whereabouts.
              <br />
              <br />
              Skin Colors:
            </p>
            <div className="row">
              <div className="col-3">
                <div
                  className={`button_wrap_color ${
                    skin == "yellow" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("yellow")}
                >
                  <div className="button_color yellow"></div>
                  <small className="text">Neutral</small>
                </div>
              </div>
              <div className="col-3">
                <div
                  className={`button_wrap_color ${
                    skin == "tan" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("tan")}
                >
                  <div className="button_color tan"></div>
                  <small className="text">Tan</small>
                </div>
              </div>
              <div className="col-3">
                <div
                  className={`button_wrap_color ${
                    skin == "brown" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("brown")}
                >
                  <div className="button_color brown"></div>
                  <small className="text">Brown</small>
                </div>
              </div>
              <div className="col-3">
                <div
                  className={`button_wrap_color ${
                    skin == "hidden" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("hidden")}
                >
                  <img src={Q} className="w-100 buttonimg" />
                </div>
              </div>
            </div>
            <br />
            <p className="text textD">
              The villagers are reported to have the following gears:
            </p>
            <div className="row icons_btns">
              <div className="col-4">
                <div
                  className="button_wrap_color"
                  onClick={() =>
                    updateAvatar(expression, expressions, setexpressions)
                  }
                >
                  <img src={expression_btn} />
                  <span className="text">Expression</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(outfit, outfits, setOutfit)}
                >
                  <img src={outfit_btn} />
                  <span className="text">Outfit</span>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(headwear, headwears, setheadwear)}
                >
                  <img src={headwear_btn} />
                  <span className="text">Headwear</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(eyewear, eyewears, seteyewear)}
                >
                  <img src={eyewear_btn} />
                  <span className="text">Eyewear</span>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(handheld, handhelds, sethandheld)}
                >
                  <img src={weapon} />
                  <span className="text">Weapon</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() =>
                    updateAvatar(hairstyle, hairstyles, setHairstyle)
                  }
                >
                  <img src={hairstyle_img} />
                  <span className="text">Hairstyle</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-none d-lg-block col-lg-1"></div> */}
          <div
            className="col-md-5 col-lg-5 pe-0 characterwrap"
            style={{ paddingLeft: "8%" }}
          >
            <div className="character">
              <img
                src={`./layers/skin/${skin}.png`}
                className="character_img"
              />
              {skin != "hidden" ? (
                <>
                  <img
                    src={`./layers/outfit/${outfits[outfit]}.png`}
                    className="character_img"
                  />
                  <img
                    src={`./layers/hairstyle/${
                      skin + "-" + hairstyles[hairstyle]
                    }.png`}
                    className="character_img"
                  />
                  <img
                    src={`./layers/expression/${expressions[expression]}.png`}
                    className="character_img"
                  />
                  <img
                    src={`./layers/weapon/${handhelds[handheld]}.png`}
                    className="character_img"
                  />
                  <img
                    src={`./layers/eyewear/${eyewears[eyewear]}.png`}
                    className="character_img"
                  />
                  <img
                    src={`./layers/headwear/${headwears[headwear]}.png`}
                    className="character_img"
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <img src={brick} className="brick" />
          </div>
        </div>
      </div>
      <div id="cityhall" className="container-fluid enterbg">
        <div className="container-xl">
          <div className="row full align-items-center justify-content-center">
            <div className="col-sm-10 col-md-5 col-xl-6 pe-xl-5 px-lg-5">
              <h1 className="head1 pt-5 headingD">Entering The City Hall</h1>
              <p className="text textD">
                The magnificent tower standing in the village is the City Hall.
                Its door is firmly locked keeping people from entering. Behind
                the door, it’s a place where private events are held, secrets
                are kept and exclusive benefits and offerings are provided.
                <br />
                <br />
                Only the owners of DSUP avatars are authorized to access this
                mysterious space.
              </p>
              <div className="row pt-3 icons">
                <div className="col-6 mb-4 pe-4">
                  <img src={ticket} className="img1 px-2" />
                  <p className="mt-4 text pe-xl-4">
                    Private events and parties
                  </p>
                </div>
                <div className="col-5 pe-xl-4">
                  <img src={couch} className="img2 px-2" />
                  <p className="mt-1 text">Voting rights in decentralisation</p>
                </div>
                <div className="col-12 my-3"></div>
                <div className="col-6">
                  <img src={scroll} className="img3 px-2" />
                  <p className="mt-3 text pe-5">
                    Ownership and rights of commercial use
                  </p>
                </div>
                <div className="col-5">
                  <img src={chest} className="img4 px-2" />
                  <p className="mt-3 text">
                    More joys and fun to be discovered
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 pe-0">
              <img src={enter} className="w-100 op" />
            </div>
          </div>
        </div>
      </div>

      <div id="slides" className="container-fluid p-0">
        <Slider ref={sliderRef} {...settings}>
          <div id="blueprint" className="container-fluid blueprintbg">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <div className="widthwrap">
                    <h1 className="head11 headingD">Blueprint</h1>
                    <p className="text textD">
                      Through building up a tunnel connecting to the digital and
                      physical worlds, we create and provide groundbreaking
                      goods and services to prosper the community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="deskdownside" className="container-fluid upsidebg">
            <div className="container-xl">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-5 px-lg-0">
                  <img src={downside} className="w-100 imgfilter" />
                </div>
                <div className="col-1 p-0 text-center">
                  {/* <img src={dots} className="mx-auto" /> */}
                </div>
                <div className="col-11 col-md-5 pe-lg-5">
                  <h1 className="head1 headingD">Downside</h1>
                  <p className="text textD mb-4 pb-2">
                    As the virtual part of DSUP, which is also the place where
                    our village is located, the boundaries of this world are our
                    imagination. Continuous search of and adventure to profound
                    areas allow us to acquire scarce resources for our
                    community.
                  </p>
                  <span className="btn-theme">“All for one”</span>
                  <p className="text textD my-4 py-2">
                    A game that is composed of games in diversified genres. It
                    provides a highly versatile environment that will take an
                    ordinary game to the next level. It also incorporates the
                    concept of decentralization.
                  </p>
                  <span className="btn-theme">Tokenization</span>
                  <p className="text textD my-4 py-2">
                    A crypto token that will enhance the ecosystem and provide
                    more utilities to the community. Stay tuned for that!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="desktunnel" className="container-fluid tunnelbg">
            <div className="container-xl">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-5 pe-lg-5">
                  <h1 className="head1 headingD">The Tunnel</h1>
                  <p className="text textD mb-4">
                    This is the core part of DSUP. The place where the magic
                    happens. Don’t be awed by any explosion sounds, as they’re
                    us breaking the rules and challenges, hewing out for your
                    tomorrow. The work here is strictly confidential, but we’ll
                    try to let you learn a bit.
                  </p>
                  <span className="btn-theme">Web 3.0 Game </span>
                  <p className="text textD my-4 pb-4">
                    Making good use of the concept of Web 3.0 and our avatar
                    NFTs, the gaming experience will be advanced with a deep use
                    of the connection and interaction with real life.
                  </p>
                </div>
                <div className="col-1 text-center p-0">
                  {/* <img src={dots2} className="dots mx-auto" /> */}
                </div>
                <div className="col-11 col-md-5 pe-lg-5">
                  <img src={tunnel} className="w-100 imgfilter" />
                </div>
              </div>
            </div>
          </div>
          <div id="deskupside" className="container-fluid upsidebg">
            <div className="container-xl">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-5">
                  <img src={upside} className="w-100 imgfilter" />
                </div>
                <div className="col-1 text-center p-0">
                  {/* <img src={dots3} className="dots mx-auto" /> */}
                </div>
                <div className="col-11 col-md-5 pe-lg-5">
                  <h1 className="head1 headingD">Upside</h1>
                  <p className="text textD mb-4 pb-4">
                    The physical part is equally essential as the digital part.
                    Among the numerous tasks we take up in this world, we’re
                    driven by one goal - to benefit our community. It’s more
                    than just building the brand and nourishing the community.
                    Actual and tangible benefits are on the way. We promise,
                    they’re unprecedented.
                  </p>
                  <span className="btn-theme">Real-life events</span>
                  <p className="text textD my-4 py-1">
                    Sell-out party, meet-ups, parties, keynotes, exhibits and
                    more allow us to say “SUP” to each other in person. Other
                    greetings are welcome. Just be creative and dope!
                  </p>
                  <span className="btn-theme">Merchandise</span>
                  <p className="text textD my-4 py-1">
                    Exclusive merchs, from large pieces to handy items, are for
                    those who love physical possessions and showcase your pride
                    as one of the villagers.
                  </p>
                  <span className="btn-theme">Collabs & Partnership</span>
                  <p className="text textD my-4 py-1">
                    Collaborating with like-minded brands, be they from the
                    Downside or the Upside, creates synergies giving you
                    unparalleled experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <div className="arrows">
          {left ? (
            <img
              src={arrow}
              className="arrow left"
              onClick={() => sliderRef.current.slickPrev()}
            />
          ) : (
            ""
          )}
          {right ? (
            <img
              src={arrowright}
              className="arrow right"
              onClick={() => sliderRef.current.slickNext()}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <div id="team" className="container-fluid teambg">
        <div className="container-xl">
          <div className="row full justify-content-center align-items-end pb-5">
            <div className="col-lg-6 col-xl-5 pb-4 pe-xl- px-lg-0">
              <h1 className="head1 headingD">Team</h1>
              <p className="text textD mb-3 pe-">
                Core members with different areas of expertise have decided to
                pick up the pickaxe and are now the constructors for building up
                the Bridge.
              </p>
              <div className="row pe-5">
                <div className="col-6 px-0">
                  <div
                    className={`teambox mb-4 ${
                      avatarBig.name == "Dron" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: dronbigmbl,
                        name: "Dron",
                        title: "Playmaker",
                        description:
                          "Lazy af, can tell by the name. Been traveling between the technology and finance sectors. Sprinkling the precise mix of these two magic powders for the best potion.",
                      })
                    }
                  >
                    <img
                      src={dran}
                      className="w-100 px-4"
                      // style={{ marginLeft: "-20px" }}
                    />
                    <h1 className="head2 mb-0">
                      <small>Dron</small>
                    </h1>
                  </div>
                  <div
                    className={`teambox mb-4 ${
                      avatarBig.name == "Ax" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: axbigmbl,
                        name: "Ax",
                        title: "Engineer",
                        description:
                          "Ex-google software engineer. Ax the rot and smash the “reboot”. Got nothing to do with cherry trees.",
                      })
                    }
                  >
                    <img
                      src={ax}
                      className="w-100 px-4"
                      // style={{ marginLeft: "-35px" }}
                    />
                    <h1 className="head2">
                      <small>Ax</small>
                    </h1>
                  </div>
                </div>
                <div className="col-6 px-0">
                  <div
                    className={`teambox mb-4 ${
                      avatarBig.name == "Vil. Z" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: vilbigmbl,
                        name: "Vil. Z",
                        title: "Marketing",
                        description:
                          "Seasoned in e-commerce. Got a disliked hobby of burning guap in media buying.",
                      })
                    }
                  >
                    <img src={vil} className="w-100 px-4" />
                    <h1 className="head2 mb-0">
                      <small>Vil. Z</small>
                    </h1>
                  </div>
                  <div
                    className={`teambox mb-4 ${
                      avatarBig.name == "Fishmonger" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: fishbigmbl,
                        name: "Fishmonger",
                        title: "Community",
                        description:
                          "“Don’t follow your dreams, follow me.” “Two for one.” He isn’t fishy. He’s trustworthy.",
                      })
                    }
                  >
                    <img src={fish} className="w-100 px-4" />
                    <h1 className="head2">
                      <small>Fishmonger</small>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-1 d-none d-xl-block"></div>
            <div className="col-lg-6 col-xl-5 p-0">
              <div className="box p-5 py-4 text-center">
                <img src={avatarBig.image} className="dronbig" />
                <h1 className="head1 headingD mb-0">{avatarBig.name}</h1>
                <p className="text borders py-2 px-5 d-inline-block">
                  {avatarBig.title}
                </p>
                <p className="text textD px-4">{avatarBig.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="faq" className="container faqbg">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <h1 className="head1 text-center head2mbl">FAQ</h1>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header text" id="headingOne">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Where should I start off?{" "}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    A great way to start is to immerse yourself in our community
                    (Discord is opening soon)
                    , where you can meet villagers coming from around the world.
                    Chilling around and chatting with them under the moon with a
                    warm breeze is what you wanna do. <br />
                    <br /> Nevertheless, we will never DM you nor ask for your
                    credentials. Every DM you receive is a scam. DON’T TRUST.
                    VERIFY.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading5">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="true"
                    aria-controls="collapse5"
                  >
                    What’s the total supply of DSUP NFTs?{" "}
                  </button>
                </h2>
                <div
                  id="collapse5"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading5"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    “Great oaks from little acorns grow.” Things are built bit
                    by bit. It’s also one of the reasons why DSUP NFTs are in
                    pixels. Inferentially, there’ll be 10,101 in total.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading7">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse7"
                    aria-expanded="true"
                    aria-controls="collapse7"
                  >
                    Which blockchain are the DSUP Avatar NFTs on?
                  </button>
                </h2>
                <div
                  id="collapse7"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading7"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    They are on the Ethereum chain. ERC721A protocol is used to
                    reduce gas fees.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading6">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse6"
                    aria-expanded="true"
                    aria-controls="collapse6"
                  >
                    What’s the roadmap?{" "}
                  </button>
                </h2>
                <div
                  id="collapse6"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading6"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    We’re agile and adaptive to changes. Thus, a phased roadmap
                    deprived us of creativity and flexibility in doing the right
                    and great things. Ideas for bridging the virtual and
                    physical worlds form the Blueprint. With this in mind, DSUP
                    will grow with optimal outcomes.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading2">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse2"
                    aria-expanded="true"
                    aria-controls="collapse2"
                  >
                    When’s the mint?
                  </button>
                </h2>
                <div
                  id="collapse2"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading2"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    Stay tuned! <br />
                    Follow us on <a href="https://twitter.com/DSUPOfficial" target="_blank">Twitter</a>{" "}
                    
                  
                    and join our community (Discord is opening soon) to keep posted.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading3">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="true"
                    aria-controls="collapse3"
                  >
                    How’ll the mint be?{" "}
                  </button>
                </h2>
                <div
                  id="collapse3"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading3"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    We haven’t decided yet. We mean, shall it be decided by us?
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading4">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="true"
                    aria-controls="collapse4"
                  >
                    How do I get whitelisted?{" "}
                  </button>
                </h2>
                <div
                  id="collapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    We like to reward those who participate and contribute to
                    building and growing the community. The details will be
                    announced via our Twitter and Discord. Follow and join,
                    don’t miss it. Stay active! Whitelisting is still open!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="footer" className="container footerbg">
        <div className="row py-5 justify-content-center align-items-center">
          <div className="col-12">
            <div
              className="text foothead text-center py-5"
              style={{ fontSize: "25px" }}
            >
              Join the village and enjoy the sunrise.
            </div>
          </div>
        </div>
      </div> */}
      <div id="footer2" className="container-fluid footer2bg">
        <div className="row pt-5 justify-content-center align-items-center">
          <div className="col-12 text-center">
            {/* <img src={logo} className="footlogo my-5" />
            <br />
            <h5 className="head1 mid my-3">Follow Us</h5> */}
            {/* <a href="https://www.instagram.com/dsupofficial/" target="_blank">
              <img src={insta} className="px-1" />
            </a>
            <a href="https://twitter.com/DSUPOfficial/" target="_blank">
              <img src={twitter} className="px-1" />
            </a>
            <a href="https://discord.gg/ZgqJu8UnsZ" target="_blank">
              <img src={discord} className="px-1" />
            </a> */}
          </div>
        </div>
        <div className="text-center pt-5 container-lg border">
          {/* <img src={footavatar} /> */}
        </div>
        <div className="container-lg">
          <div className="row pt-4 pb-2">
            <div className="col-sm-6 text-center text-md-left">
              <p className="text" style={{ fontSize: "14px" }}>
                © 2022 Seer Valley Inc.
              </p>
            </div>
            <div className="col-sm-6 text-md-right text-center">
              <p className="text">
                <a
                  href="/privacy-policy"
                  className="text"
                  style={{ fontSize: "14px" }}
                >
                  Privacy Policy
                </a>
                <a
                  href="/terms-of-use"
                  className="ps-3 text"
                  style={{ fontSize: "14px" }}
                >
                  Terms of Use
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
