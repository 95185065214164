import { useState } from "react";
import logo from "../images/logo.png";

function Terms() {
  const [hide, sethide] = useState(false);

  return (
    <>
      {window.innerWidth > 768 ? (
        <nav
          className={`px-4 navbar navmbl navbar-dark navbar-expand-md bg-transparent ${
            hide ? "nav-up" : ""
          }`}
        >
          <div className="container-fluid p-0">
            <a className="navbar-brand" href="/#">
              <img src={logo} style={{ width: "65px" }} />
            </a>
            <button
              className="navbar-toggler collapsed position-relative"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              id="toggle"
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                className="menubar"
              ></div>
              <ul className="navbar-nav navbar-navmbl ms-auto pt-3 align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="/#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#avatars">
                    Avatars
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#enter-cityhall">
                    City Hall
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#blueprint">
                    Blueprint
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#team">
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#faq">
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/DSUPOfficial/" target="_blank">
                    <i
                      className="fab fa-twitter ms-auto px-lg-3 px-1 px-md-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                  <a
                    href="https://www.instagram.com/dsupofficial/"
                    target="_blank"
                  >
                    <i
                      className="fab fa-instagram ms-auto px-lg-3 px-1 px-md-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                  <a href="https://discord.gg/ZgqJu8UnsZ/" target="_blank">
                    <i
                      className="fab fa-discord ms-auto px-lg-3 px-1 px-md-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <nav
          className={`px-4 navbar navmbl navbar-dark navbar-expand-md bg-transparent ${
            hide ? "nav-up" : ""
          }`}
        >
          <div className="container-fluid p-0">
            <a className="navbar-brand" href="/">
              <img src={logo} style={{ width: "65px" }} />
            </a>
            <button
              className="navbar-toggler collapsed position-relative"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              id="toggle"
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
            {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="cross">

                </span>
                <span className="navbar-toggler-icon"></span>
              </button> */}
            <div
              className="collapse navbar-collapse"
              id="navbarNav"
              // style={{ backgroundColor: "#182030bf" }}
            >
              <div
                style={
                  {
                    // position: "fixed",
                    // width: "100vw",
                    // height: "100vh",
                    // left: "0",
                    // top: "0",
                    // zIndex: "0",
                    // backgroundColor: "#182030e6",
                  }
                }
                // onClick={() => {
                //   document
                //     .getElementById("navbarNav")
                //     .classList.toggle("show");
                //   document
                //     .getElementById("toggle")
                //     .classList.toggle("collapsed");
                // }}

                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                className="menubar"
              ></div>
              <ul
                className="navbar-nav navbar-navmbl ms-auto pt-3"
                // style={{ position: "absolute" }}
              >
                <li className="nav-item">
                  <a className="nav-link" href="/#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#avatars">
                    Avatars
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#enter-cityhall">
                    City Hall
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#blueprint">
                    Blueprint
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#team">
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#faq">
                    FAQ
                  </a>
                </li>
                <hr className="my-0 mx-2 bg-white" style={{ opacity: 1 }} />
                <li className="nav-item iconmbl">
                  <a
                    href="https://twitter.com/DSUPOfficial/"
                    target="_blank"
                    className="nav-link d-flex justify-content-space-between align-items-center"
                  >
                    Twitter
                    {/* <img src={twitter} className="px-1" /> */}
                    <i
                      className="fab fa-twitter ms-auto d-block"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </a>
                </li>
                <li className="nav-item iconmbl">
                  <a
                    href="https://www.instagram.com/dsupofficial/"
                    target="_blank"
                    className="nav-link d-flex justify-content-space-between align-items-center"
                  >
                    Instagram
                    {/* <img src={insta} className="px-1" /> */}
                    <i
                      className="fab fa-instagram ms-auto d-block"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </a>
                </li>
                <li className="nav-item iconmbl">
                  <a
                    href="https://discord.gg/ZgqJu8UnsZ/"
                    target="_blank"
                    className="nav-link d-flex justify-content-space-between align-items-center"
                  >
                    Discord
                    {/* <img src={discord} className="px-1" /> */}
                    <i
                      className="fab fa-discord ms-auto d-block"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 p-5">
            <h1 class="head1 headingD">Terms Of Use</h1>
            <p className="text">
              Last revised: February 22nd, 2023
              <br />
              These Terms of Use (the “Terms”) set forth the terms and
              conditions under which Seer Valley, Inc (“Seer Valley,” “we,”
              “us,” or “our”) and you (“you” or “your”) agree to be governed as
              it relates to any of your activity in DSUP, a platform created and
              owned by Seer Valley (“DSUP”), and our websites (the "Sites").
              These Terms govern your access to, use of, and interaction with
              DSUP and Sites, including any content, functionality, smart
              contracts and services offered on or through DSUP and Sites
              (collectively, the “Services”).
              <br />
              <br />
              It is important that you read the Terms carefully, as your use of
              the Services will automatically constitute your agreement to be
              legally bound by the Terms set out herein. In addition to any
              applicable license agreements on Sites, these Terms also govern
              your use or ordering of the digital and physical products provided
              through or in connection with Sites.
              <br />
              <br />
              The Privacy Policy and all such additional terms, guidelines, and
              rules as set forth on Sites are hereby incorporated by reference
              into these Terms and expressly agreed to and acknowledged by you.
              Our Privacy Policy can be found at URL:
              www.downsideup.com/privacypolicy
              <br />
              <br />
              DSUP is offered and available to users who are 18 years of age or
              older. By accessing or using DSUP, including but not limited to
              signing up for an account on Sites, connecting your cryptocurrency
              wallet to the Services and using or accessing the Services, you
              represent and warrant that you are of legal age to form a binding
              contract with Seer Valley and meet the foregoing eligibility
              requirements. If you do not meet all of these requirements, you
              must not access or use DUSP.
              <br />
              <br />
              We reserve the right to change or modify portions of these Terms
              at any time. If we do this, we will post the changes on this page
              and will indicate at the top of this page the date these Terms
              were last revised. Any such changes will become effective when
              posted, unless a different date is indicated by us. Your continued
              use of the Site or any other Services after the date any such
              changes become effective constitutes your acceptance of the new
              Terms of Use.
            </p>
            <h1 className="head2">NON-FUNGIBLE TOKENS & DIGITAL ASSETS</h1>
            <p className="text">
              Non-fungible tokens and other digital assets (collectively,
              "Company Digital Products") are, from time to time, offered by us
              on or through DSUP or other software, media channels or
              applications. In order to participate in the purchase or other
              receipt of Company Digital Products, you agree that you will
              comply with the specific terms and conditions, if any, associated
              with the respective Company Digital Products in addition to these
              Terms. You acknowledge and agree that any payments that you make
              for Company Digital Products that are processed through
              third-party partners will be subject to separate terms and
              conditions, if any, associated with such third-party partners. You
              acknowledge and agree that we are not liable for any claims or
              damages that may arise as a result of any payments or transactions
              that you engage in through use of any third-party services. By
              purchasing Company Digital Products, you acknowledge and agree to
              the Terms set out herein. Ownership of each Company Digital
              Product is mediated entirely by the Smart Contract and the
              Ethereum Network: at no point may we seize, freeze, or otherwise
              modify the ownership.
              <br />
              <br />
              Company Digital Products purchased from Sites may be resold and/or
              traded by the initial purchaser on third-party marketplaces or
              exchange sites (such transactions, “Secondary Transactions”). We
              are not a party to any the terms and conditions and/or agreement
              of the Secondary Transactions. We are not liable for any claims or
              damages that may arise as a result of your Secondary Transactions.
              <br />
              <br />
              You are solely responsible for any and all sales, use, value-added
              and other taxes, duties, and assessments now or hereafter claimed
              or imposed by any governmental authority, associated with your use
              of the Services and Company Digital Products (including, without
              limitation, any taxes that may become payable as the result of
              your ownership, transfer, purchase, or sale of a Company Digital
              Product).
            </p>
            <h1 className="head2">DSUP GENESIS</h1>
            <p className="text">
              DSUP Genesis is a collection of non-fungible tokens running on
              Ethereum network (each non-fungible token under DSUP Genesis,
              "DSUP Genesis NFT"). The artwork, image or works of authorship
              assocated with a specific DSUP Genesis NFT is referred to "DSUP
              Genesis NFT Art".
              <br />
              <br />
              Subject to your continued compliance with these Terms, we grants
              you, as the owner of a DSUP Genesis NFT, the following rights:
              <br />
              (a) a non-exclusive, worldwide, royalty-free license to use,
              reproduce, display, modify, and create derivative works of the
              DSUP Genesis NFT Art for your DSUP Genesis NFT solely for the
              following purposes: (i) personal, non-commercial use; or (ii) as
              part of a third party website or application that permits the
              inclusion, involvement, or participation of your DSUP Genesis NFT,
              provided that the website/application cryptographically verifies
              each DSUP Genesis NFT owner’s rights to display the DSUP Genesis
              NFT Art to ensure that only the actual owner can display the DSUP
              Genesis NFT Art, and provided that the DSUP Genesis NFT Art is no
              longer visible once the owner of the DSUP Genesis NFT leaves the
              website/application.
              <br />
              (b) a non-exclusive, worldwide, royalty-free license to use, copy,
              reproduce and display the DSUP Genesis NFT Art for your DSUP
              Genesis NFT for any activity that is performed with an intent to
              generate revenue ("Commerical Use"). The license is sub-licensable
              if and only if it is used for Commerical Use. The license in this
              sub-section (b) does not include a right to create derivative
              works of the DSUP Genesis NFT Art except as necessary to adapt and
              depict the DSUP Genesis NFT Art in or on the goods or media
              created pursuant to this license, or as otherwise permitted, in
              writing, by us.
              <br />
              <br />
              Notwithstanding any of the above, you may not use the DSUP Genesis
              NFT Art in any way that constitutes unlawful, defamatory,
              harassing, abusive, fraudulent, racist, hateful, vulgar, cruel,
              illegal or obscene activity, or that promotes any such activity.
            </p>
            <h1 className="head2">USER CONTENT</h1>
            <p className="text">
              You acknowledge and agree that you may choose to submit comments,
              bug reports, ideas or other feedback about the website, the
              Services and Company Digital Products (collectively, “Feedback”)
              and you agree that we are free to use such Feedback in any way we
              choose without additional compensation to you and you hereby grant
              us a perpetual, irrevocable, nonexclusive, worldwide license to
              incorporate and use the Feedback for any purpose.
            </p>
            <h1 className="head2">OWNERSHIP OF COMPANY CONTENT</h1>
            <p className="text">
              Source code, databases, functionality, software, website designs,
              images, audio, video, text, graphics or other material included on
              Sites, or otherwise made available througth the Services
              (collectively, the “Company Content”) and the trademarks, service
              marks, and logos contained therein (the “Company Marks”) are owned
              or controlled by us or licensed to us, and are protected by
              copyright and trademark laws and various other intellectual
              property rights. All logos and trademarks, service marks, logos
              and trade names displayed on or in connection with the Services
              are the registered and unregistered trademarks and service marks
              of Seer Valley or third parties in the United States and/or other
              countries. Your use of the Services does not grant you any
              ownership over any Seer Valley Content, and except for the limited
              license we grant you under these Terms, your use of the Services
              does not grant you any license or permission under any copyright,
              trademark or other intellectual property rights of Seer Valley or
              any third party. We reserve all rights not expressly granted to
              you in these Terms.
              <br />
              <br />
              You are granted a limited, non-exclusive, non-sublicensable and
              non-transferable license to use the Services as they are provided
              to you by us, only as set forth in these Terms. Except as
              expressly permitted in these Terms, you may not reproduce,
              distribute, adapt, modify, translate, create derivative works
              from, publish or otherwise use any portion of the Services or
              Company Content for any purpose without express prior written
              permission from us or the applicable rights holder. Any commercial
              exploitation of the Services or Seer Valley Content without
              express prior written permission from us or the applicable rights
              holder is strictly prohibited.
              <br />
              <br />
              All software and software-as-a-service used in connection with the
              Services (“Software”) is proprietary to Seer Valley or to third
              parties, and except as may be required to exercise the foregoing
              license grant, any use, sale, translation, redistribution,
              decompilation, reverse engineering, disassembly or reduction of
              such software to human-readable form is prohibited. You
              acknowledge and agree that Seer Valley may update the Software
              without notice, at any time and in our sole discretion, and that
              these Terms will apply to any updated versions.
            </p>
            <h1 className="head2">COVENANTS AND RESTRICTIONS</h1>
            <p className="text">
              In order to use the website or the Services, you agree not to, and
              you will not permit any third party to, do or attempt to do any of
              the following without our prior written consent: (a) violate any
              applicable local, state, national or international law, or any
              regulations having the force of law, including but not limited to
              those of the U.S. Department of Treasury's Office of Foreign
              Assets Control (“OFAC”), or which would involve proceeds of any
              unlawful activity;
              <br />
              (b) copy or adapt the website’s software or code;
              <br />
              (c) circumvent, interfere with, disable or disrupt the website or
              servers or networks connected to the website or any
              security-related features of the website in any manner, including
              but not limited to uploading or transmitting (or attempting to
              upload or to transmit) Trojan horses, viruses, or other similar
              harmful materials;
              <br />
              (d) decipher, decompile, disassemble, or reverse engineer any of
              the software comprising or in any way making up a part of the
              website;
              <br />
              (e) attempt to bypass any measures of the website designed to
              prevent or restrict access to the website, or any portion of the
              website;
              <br />
              (f) use the Company Digital Products to advertise or offer to sell
              or buy any goods or services for any purpose that is not
              specifically authorized herein;
              <br />
              (g) use any Company Digital Productss to further or promote any
              criminal activity or enterprise or provide instructional
              information about illegal activities, including but not limited to
              any activity in relation to laundering money or financing
              terrorism;
              <br />
              (h) use any Company Digital Products to carry out financial
              activities subject to registration or licensing, including but not
              limited to creating, listing, or buying securities, commodities,
              options, real estate, or debt instruments;
              <br />
              (i) transmit any material or content that is pornographic,
              threatening, harassing, libelous, hate-oriented, harmful,
              defamatory, racist, xenophobic, or illegal;
              <br />
              (j) transmit any material or content that is inappropriate for
              families or otherwise suitable only for adults;
              <br />
              (k) transmit any material or content that attempts to falsely
              state or otherwise misrepresent your identity or affiliation with
              a person or entity;
              <br />
              (l) transmit material or content that promotes, provides, or
              relates to instructional information about illegal activities or
              promotes physical harm or injury against any individual or group;
              <br />
              (m) intrude into a third party’s computer system, engage in any
              activity that may damage, control, interfere with or intercept all
              or part of a third party’s computer system and violate its
              integrity or security, or otherwise transmit any materials or
              content that is harmful for third party information systems
              (including but not limited to viruses, worms, Trojans);
              <br />
              (o) use the Services for purposes other than those for which they
              were designed; and
              <br />
              (p) engage in activity which operates to defraud Seer Valley, Seer
              Valley users, or any other person.
              <br />
              <br />
              Seer Valley is not permitted to engage in any transactions with
              any person, entity, or country prohibited by any applicable export
              control and sanctions laws and regulations of the United States
              and any other applicable governmental authority, including without
              limitation, the U.S. Export Administration Regulations and U.S.
              sanctions regulations (“Export Control and Sanctions Laws”),
              including, without limitation, the prohibition against
              transactions with: (i) a national or resident of any country
              subject to U.S. sanctions or similar restrictions or (ii) anyone
              on the U.S. Treasury Department’s list of Specially Designated
              Nationals or other sanctions lists. You are not permitted to
              purchase any Company Digital Products or engage in any other
              transactions with us through Sites or otherwise if any of the
              foregoing restrictions apply. In the event that you breach this
              provision, Seer Valley reserves the right to the fullest extent
              possible to cancel any transaction or pursue other measures to
              comply with Export Control and Sanctions Laws.
            </p>
            <h1 className="head2">REPRESENTATIONS AND WARRANTIES</h1>
            <p className="text">
              You warrant and represent that: (a) you have the legal capacity
              and you agree to comply with these Terms; (b) you will not use the
              website, Services or Company Digital Products for any illegal or
              unauthorized purpose; (c) your use of the Sites, Services or
              Company Digital Products will not violate any applicable law or
              regulation; (d) none of the Services and/or other materials
              provided, created, produced, compiled, developed or otherwise
              supplied hereunder, shall violate or infringe upon the copyright,
              trademark, privacy, creative or other rights of any person, firm,
              corporation or other third party by reason of distribution,
              exhibition or other use; (e) you are not a national or resident of
              any country subject to U.S. sanctions or similar restrictions; and
              (f) you are not on the U.S. Treasury Department’s list of
              Specially Designated Nationals or other sanctions lists.
            </p>
            <h1 className="head2">DISCLAIMERS AND LIMITATION OF LIABILITY</h1>
            <p className="text">
              THE SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, COMPANY
              DIGITAL PRODUCTS, OTHER PRODUCTS (INCLUDING SOFTWARE) AND OTHER
              SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
              THE SERVICES ARE PROVIDED BY SEER VALLEY ON AN “AS IS” AND “AS
              AVAILABLE” BASIS WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY
              KIND, EXPRESS OR IMPLIED.
              <br />
              <br />
              YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES AND COMPANY
              DIGITAL PRODUCTS IS AT YOUR SOLE RISK. TO THE FULL EXTENT
              PERMISSIBLE BY LAW, SEER VALLEY DISCLAIMS ALL WARRANTIES, EXPRESS
              OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
              OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SEER
              VALLEY DOES NOT REPRESENT OR WARRANT THAT THE SERVICES,
              INFORMATION, CONTENT, MATERIALS, Company Digital Products, OTHER
              PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR
              OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES, ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS. SEER VALLEY DOES NOT
              REPRESENT OR WARRANT THAT CONTENT OR FEATURE CONTAINED ON THE
              SERVICES OR COMPANY DITIGAL PRODUCTS IS ACCURATE, COMPLETE,
              RELIABLE, CURRENT OR ERROR-FREE.
              <br />
              <br />
              COMPANY DIGITAL PRODUCTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST
              ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM
              NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE
              DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE
              ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT SEER VALLEY CAN EFFECT
              THE TRANSFER OF TITLE OR RIGHT IN ANY Company Digital Products.
              <br />
              <br />
              TO THE FULLEST EXTENT PERMITTED BY LAW, SEER VALLEY WILL NOT BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS OF PROFITS OR ANY
              INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
              PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS,
              THE SERVICES, COMPANY DITIGAL PRODUCTS OR THIRD PARTY SITES AND
              PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF
              PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE,
              LOSS OF GOODWILL, OR LOSS OF DATA, WHETHER CAUSED BY TORT
              (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF
              FORESEEABLE AND EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES.
              <br />
              <br />
              IN ADDITION TO THE FOREGOING DISCLAIMER, SEER VALLEY SPECIFICALLY
              DISCLAIMS LIABILITY, AND YOU HEREBY WAIVE AND RELEASE ANY AND ALL
              CLAIMS, ARISING OUT OF OR IN CONNECTION WITH: ANY BLOCKCHAIN OR
              RELATED TECHNOLOGY; ANY DIGITAL WALLET OR SIMILAR TECHNOLOGY OR
              RELATED SERVICE; THE TRANSFER OR LOSS OF ANY COMPANY DIGITAL
              PRODUCT OR THE INABILITY TO DEMONSTRATE OWNERSHIP OR CONTROL OF
              ANY COMPANY DIGITAL PRODUCT, AND ANY MARKETPLACE OR OTHER PLATFORM
              FOR BUYING, SELLING OR TRANSFERRING ANY COMPANY DIGITAL PRODUCT.
              <br />
              <br />
              FURTHER, TO THE FULL EXTENT PERMISSIBLE BY LAW, THE AGGREGATE
              LIABILITY OF SEER VALLEY ARISING OUT OF OR IN CONNECTION WITH
              THESE TERMS , THE SERVICES OR COMPANY DITIGAL PRODUCTS WILL NOT
              EXCEED US $100.00. THESE LIMITATIONS AND EXCLUSIONS REGARDING
              DAMAGES APPLY EVEN IF ANY REMEDY FAILS TO PROVIDE ADEQUATE
              COMPENSATION.
            </p>
            <h1 className="head2">MODIFYING AND TERMINATING THE SERVICE</h1>
            <p className="text">
              At any time and without notice, Seer Valley reserves the right to
              modify or discontinue, temporarily or permanently, offering all or
              any part of the Services. Seer Valley may, in its sole discretion
              and at any time, block anyone from accessing DSUP, and/or block or
              prevent your access to and use of any Services or features
              governed by these Terms. Seer Valley will not be liable to you or
              to any third party for any modification, suspension, or
              discontinuance of the Services, including in relation to any
              Company Digital Products.
            </p>
            <h1 className="head2">INDEMNIFICATION</h1>
            <p className="text">
              To the fullest extent permitted by applicable law, you agree to
              indemnify, defend and hold harmless Seer Valley, its affiliates
              and its and their directors, officers and employees (collectively,
              the “Indemnitees”), from and against all actual or alleged third
              party claims, damages, awards, judgments, losses, liabilities,
              obligations, penalties, interest, fees, expenses (including,
              without limitation, attorneys' fees and expenses) and costs
              (including, without limitation, court costs, costs of settlement
              and costs of pursuing indemnification and insurance), of every
              kind and nature whatsoever, whether known or unknown, foreseen or
              unforeseen, matured or unmatured, or suspected or unsuspected, in
              law or equity, whether in tort, contract or otherwise
              (collectively, “Claims”), including, but not limited to, damages
              to property or personal injury, that are caused by, arise out of
              or are related to (i) your use or misuse of the Sites, the
              Services, feature or content contained in the Services, or the
              Company Digital Products, (ii) your violation of these Terms, any
              representation, warranty or covenant hereunder, (iii) your
              violation of the rights of a third party, including another user
              of the Sites, or (iv) the negligence, fault or default of you,
              your employees, authorized agents, servants or independent
              contractors hired by you, or any subcontractor hired by any of the
              foregoing. You agree to notify Seer Valley promptly, in writing,
              of any third-party Claims or legal action of which you have
              knowledge, which is in any way related to these Terms or your
              obligations hereunder and cooperate with the applicable Indemnitee
              in defending such Claims. You agree that the Indemnitees shall
              have the right to control of the defense or settlement of any
              third-party Claims. You further agree that the warranties,
              representations and indemnifications contained herein shall
              survive any termination or expiration of these Terms or your
              relationship with Seer Valley. THIS INDEMNITY IS IN ADDITION TO,
              AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN
              AGREEMENT BETWEEN YOU AND US.
            </p>
            <h1 className="head2">GOVERNING LAW AND DISPUTE RESOLUTION</h1>
            <p className="text">
              These Terms will be governed by the laws of the State of Delaware
              without regard to the conflict of law provisions thereof and
              regardless of where you live or from where you access or use Sites
              or order products therefrom. If either party brings against the
              other party any proceeding arising out of these Terms, that party
              may bring proceedings only in the federal courts located within
              the State of Delaware and no other courts, and each party hereby
              submits to the exclusive jurisdiction of those courts for purposes
              of any such proceeding.
            </p>
            <h1 className="head2">MISCELLANEOUS</h1>
            <p className="text">
              These Terms (along with other policies found on Sites) constitute
              the entire agreement between you and Seer Valley and govern your
              use of the Services and Company Digital Products, superseding any
              prior oral and written agreements. Except as otherwise provided
              herein, these Terms are intended solely for the benefit of the
              parties and are not intended to confer third party beneficiary
              rights upon any other person or entity. You also may be subject to
              additional terms and conditions that may apply when you use
              affiliate or third party services, third party content or third
              party software. The failure of Seer Valley to exercise or enforce
              any right or provision of these Terms will not constitute a waiver
              of such right or provision.
              <br />
              <br />
              These Terms inure to the benefit of and binds the successors,
              assigns, heirs, executors and administrators of the parties.
              However, you may not assign or delegate any right or duty
              hereunder without written consent from Seer Valley. Any attempt to
              do so is null and void.
              <br />
              <br />
              You agree that regardless of any statute or law to the contrary,
              any claim or cause of action arising out of or related to use of
              the Services, Company Digital Products or these Terms must be
              filed within one (1) year after such claim or cause of action
              arose or be forever barred.
              <br />
              <br />
              If any provision of these Terms is held invalid or unenforceable,
              the remainder of the Terms will remain in full force and effect.
              If any provision is held invalid or unenforceable with respect to
              particular circumstances, it will remain in full force and effect
              in all other circumstances.
            </p>{" "}
          </div>
        </div>
      </div>
      <div id="footer2" className="container-fluid footer2bg">
        <div className="row pt-5 justify-content-center align-items-center">
          <div className="col-12 text-center">
            {/* <img src={logo} className="footlogo my-5" />
            <br />
            <h5 className="head1 mid my-3">Follow Us</h5> */}
            {/* <a href="https://www.instagram.com/dsupofficial/" target="_blank">
              <img src={insta} className="px-1" />
            </a>
            <a href="https://twitter.com/DSUPOfficial/" target="_blank">
              <img src={twitter} className="px-1" />
            </a>
            <a href="https://discord.gg/ZgqJu8UnsZ/" target="_blank">
              <img src={discord} className="px-1" />
            </a> */}
          </div>
        </div>
        <div className="text-center pt-5 container-lg border">
          {/* <img src={footavatar} /> */}
        </div>
        <div className="container-lg">
          <div className="row pt-4 pb-2">
            <div className="col-sm-6 text-center text-md-left">
              <p className="text" style={{ fontSize: "14px" }}>
                © 2022 Seer Valley Inc.
              </p>
            </div>
            <div className="col-sm-6 text-md-right text-center">
              <p className="text">
                <a
                  href="/privacy-policy"
                  className="text"
                  style={{ fontSize: "14px" }}
                >
                  Privacy Policy
                </a>
                <a
                  href="/terms-of-use"
                  className="ps-3 text"
                  style={{ fontSize: "14px" }}
                >
                  Terms of Use
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
