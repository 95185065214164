import "../App mbl.css";
import aboutmbl1 from "../images/aboutmbl1.png";
import aboutmbl2 from "../images/aboutmbl2.png";
import avatarsbgmbl from "../images/avatarsbgmbl.png";
import brickmbl from "../images/brickmbl.png";
import entermbl from "../images/entermbl.png";
import dronbigmbl from "../images/dronbigmbl.png";
import fishbigmbl from "../images/fishbigmbl.png";
import axbigmbl from "../images/axbigmbl.png";
import vilbigmbl from "../images/vilbigmbl.png";
import fishmbl from "../images/fishmbl.png";
import axmbl from "../images/axmbl.png";
import vilmbl from "../images/vilmbl.png";
import dranmbl from "../images/dronmbl.png";
import downsidembl from "../images/downsidembl.png";
import tunnelbgmbl from "../images/tunnelmbl.png";
import upsidembl2 from "../images/upsidemblnew.png";

import arrow from "../images/arrow.png";
import arrowright from "../images/arrow right.png";
import Q from "../images/Q.png";
import weapon from "../images/weapon.png";
import hairstyle_img from "../images/hairstyle.png";
import expression_btn from "../images/expression.png";
import outfit_btn from "../images/outfit.png";
import headwear_btn from "../images/headwear.png";
import eyewear_btn from "../images/eyewear.png";
import ticket from "../images/ticket.png";
import couch from "../images/couch.png";
import scroll from "../images/scroll.png";
import chest from "../images/chest.png";
import logo from "../images/logo.png";
import insta from "../images/insta.png";
import twitter from "../images/twitter.png";
import discord from "../images/discord.png";
import footavatar from "../images/footavatar.png";
import { useEffect, useRef, useState } from "react";

import Slider from "react-slick";

import banner from "../images/banner.mp4";

export default function HomeMBL() {
  const outfits = ["greek dress-all", "red haori-all"];
  const hairstyles = [1, 2];
  const expressions = ["contempt-male-blue", "relaxed"];
  const handhelds = ["beer-all", "fire sword-all"];
  const eyewears = ["null", "sunglasses"];
  const headwears = ["null", "devil horns-all", "wreath"];

  const [skin, setSkin] = useState("yellow");
  const [outfit, setOutfit] = useState(1);
  const [hairstyle, setHairstyle] = useState(1);
  const [expression, setexpressions] = useState(1);
  const [handheld, sethandheld] = useState(0);
  const [eyewear, seteyewear] = useState(0);
  const [headwear, setheadwear] = useState(0);
  const [hide, sethide] = useState(false);
  const [left, setleft] = useState(false);
  const [right, setright] = useState(true);
  const [slide, setslide] = useState(0);
  const [avatarBig, setAvatarBig] = useState({
    image: dronbigmbl,
    name: "Dron",
    title: "Playmaker",
    description:
      "Lazy af, can tell by the name. Been traveling between the technology and finance sectors. Sprinkling the precise mix of these two magic powders for the best potion.",
  });

  function updateAvatar(trait, traits, setTrait) {
    if (trait + 1 == traits.length) setTrait(0);
    else setTrait(trait + 1);
  }

  const sliderRef = useRef(null);
  // const handleWheel = (event) => {
  //   if (
  //     document.getElementById("slides").getBoundingClientRect().top < 5 &&
  //     document.getElementById("slides").getBoundingClientRect().top > -5
  //   ) {
  //     if (event.deltaY > 0) {
  //       sliderRef.current.slickNext();
  //       if (sliderRef.current.innerSlider.state.currentSlide != 3)
  //         event.preventDefault();
  //     } else {
  //       sliderRef.current.slickPrev();
  //       if (sliderRef.current.innerSlider.state.currentSlide != 0)
  //         event.preventDefault();
  //     }
  //   }
  // };
  useEffect(() => {
    let z = document.getElementsByClassName("nav-item");
    for (let i = 0; i < z.length; i++)
      z[i].addEventListener("click", () => {
        document.getElementById("navbarNav").classList.remove("show");
        document.getElementById("toggle").classList.add("collapsed");
        document.getElementById("toggle").setAttribute("aria-expanded", false);
      });
    // .forEach((e) => {
    //   e.addEventListener("wheel", handleWheel, { passive: false });
    // });
    // const sliderElement = document.getElementById("slides");
    // sliderElement.addEventListener("wheel", handleWheel, { passive: false });
    // return () => {
    //   sliderElement.removeEventListener("wheel", handleWheel);
    // };
  }, []);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (i, j) => {
      document
        .getElementById("blueprintmbl")
        .scrollIntoView({ behavior: "smooth", block: "start" });
      setslide(j);
      if (i == 3) {
        setright(false);
      } else if (i == 0) {
        setleft(false);
      } else {
        setleft(true);
        setright(true);
      }
    },
  };

  const header = (e) => {
    if (document.getElementById("main").scrollTop < 300) sethide(false);
    else {
      if (e.deltaY > 10) sethide(true);
      else if (e.deltaY < 0) sethide(false);
    }
  };
  const header2 = (e) => {
    if (document.getElementById("main").scrollTop < 300) sethide(false);
    else {
      if (e.clientY > 150) sethide(true);
      else if (e.clientY < 80) sethide(false);
    }
  };

  const handleswipe = (d) => {
    console.log(d);
  };

  return (
    <div
      id="main"
      className="main mbl"
      onWheel={(event) => header(event)}
      onMouseMove={(event) => header2(event)}
    >
      <div id="home" className="container-fluid herobgmbl">
        <div className="row justify-content-center align-items-end">
          <nav
            className={`navbar navmbl navbar-dark navbar-expand-md bg-transparent ${
              hide ? "nav-up" : ""
            }`}
          >
            <div className="container-fluid p-0">
              <a className="navbar-brand" href="#">
                <img src={logo} style={{ width: "65px" }} />
              </a>
              <button
                className="navbar-toggler collapsed position-relative"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                id="toggle"
              >
                <span> </span>
                <span> </span>
                <span> </span>
              </button>
              {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="cross">

                </span>
                <span className="navbar-toggler-icon"></span>
              </button> */}
              <div
                className="collapse navbar-collapse"
                id="navbarNav"
                // style={{ backgroundColor: "#182030bf" }}
              >
                <div
                  style={
                    {
                      // position: "fixed",
                      // width: "100vw",
                      // height: "100vh",
                      // left: "0",
                      // top: "0",
                      // zIndex: "0",
                      // backgroundColor: "#182030e6",
                    }
                  }
                  // onClick={() => {
                  //   document
                  //     .getElementById("navbarNav")
                  //     .classList.toggle("show");
                  //   document
                  //     .getElementById("toggle")
                  //     .classList.toggle("collapsed");
                  // }}

                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  className="menubar"
                ></div>
                <ul
                  className="navbar-nav navbar-navmbl ms-auto pt-3"
                  // style={{ position: "absolute" }}
                >
                  <li className="nav-item">
                    <a className="nav-link" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#aboutmbl">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#avatarsmbl">
                      Avatars
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#cityhallmbl">
                      City Hall
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#blueprintmbl">
                      Blueprint
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#team">
                      Team
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#faq">
                      FAQ
                    </a>
                  </li>
                  <hr className="my-0 mx-2 bg-white" style={{ opacity: 1 }} />
                  <li className="nav-item iconmbl">
                    <a
                      href="https://twitter.com/DSUPOfficial/"
                      target="_blank"
                      className="nav-link d-flex justify-content-space-between align-items-center"
                    >
                      Twitter
                      {/* <img src={twitter} className="px-1" /> */}
                      <i
                        className="fab fa-twitter ms-auto d-block"
                        style={{ fontSize: "22px" }}
                      ></i>
                    </a>
                  </li>
                  <li className="nav-item iconmbl">
                    <a
                      href="https://www.instagram.com/dsupofficial/"
                      target="_blank"
                      className="nav-link d-flex justify-content-space-between align-items-center"
                    >
                      Instagram
                      {/* <img src={insta} className="px-1" /> */}
                      <i
                        className="fab fa-instagram ms-auto d-block"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </a>
                  </li>
                  {/*<li className="nav-item iconmbl">
                    <a
                      href=""
                      target="_blank"
                      className="nav-link d-flex justify-content-space-between align-items-center"
                    >
                      Discord
                      <img src={discord} className="px-1" /> <--has to turn it to comment after activate the discord
                      <i
                        className="fab fa-discord ms-auto d-block"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </a>
                </li>*/}
                </ul>
              </div>
            </div>
          </nav>
          <div className="col-12 p-0">
            <h1
              style={{
                position: "absolute",
                top: "40%",
                transform: "translate(0,-50%)",
              }}
              className="head1mbl"
            >
              from here to there...
            </h1>
            <video
              src={banner}
              autoPlay
              playsInline
              muted
              loop
              className="bannervideombl p-0"
              style={{ marginBottom: "-10px" }}
            ></video>
          </div>
        </div>
      </div>

      <div className="p-0 container-fluid aboutbgmbl mt-1 position-relative">
        <span id="aboutmbl"></span>
        <img src={aboutmbl1} className="w-100 pt-4" />
        <div className="container">
          <div className="row align-items-cente">
            <div className="col-12">
              <h1 className="head1 head2mbl">About</h1>
              <p className="text textmbl1 pb-5">
                DSUP - Downside Up - is a revolutionary brand that we are building together.
                <br />
                <br />
                Our journey starts in the digital realm and extends to disrupt the physical 
                world. We aim to harness the power of intangible assets, constructing and 
                creating to deliver true value and unprecedented excitement.
                <br />
                <br />At the heart of DSUP lies a gateway between the digital and physical 
                worlds - a collection of 10,101 pixel avatars, each a unique and distinct 
                entrance to the tunnel that connects the two realms.
                <br />
                <br />
                By owning one of these avatars, you gain access to a range of exclusive 
                holders-only benefits, including private club membership, live events, 
                and voting rights to help us achieve decentralization. Along the way, 
                we will continue to reveal and realize groundbreaking benefits, as we 
                work together to build a better future.
              </p>
            </div>
          </div>
        </div>
        <img src={aboutmbl2} className="w-100" />
      </div>
      <div id="value" className="container-fluid valuebgmbl">
        <div className="container">
          <div className="row">
            <div className="col-12 m-0 p-0 pt-5 pb-5">
              <h1 className="head1 head2mbl">Values</h1>
              <p className="text textmbl1">
                Our values serve as a compass, guiding us as we respond to 
                ever-changing circumstances and chart a course for development 
                in our village. They provide a foundation for us to grow with 
                purpose and vigour in the exciting new landscape of Web3.
              </p>

              <h1 className="head2 pt-2 mb-0">Community</h1>
              <p className="text textmbl1">
                You are our top priority. We take care of what you need and
                think out of what you want.
              </p>

              <h1 className="head2 pt-2 mb-0">Impact</h1>
              <p className="text textmbl1">
                Impact is what DSUP makes. Every step counts. We make and only
                make the most efficient move and give out the most powerful
                punch.
              </p>

              <h1 className="head2 pt-2 mb-0">Innovation</h1>
              <p className="text textmbl1">
                We amaze the community with continuous innovations. Your
                astonishments are our greatest motivation to go beyond and bring
                in new and original stuff.
              </p>
            </div>
          </div>
        </div>
      </div>

      <span className="d-block" id="avatarsmbl"></span>
      <img src={avatarsbgmbl} className="w-100" />
      <div id="avatar" className="container avatarbgmbl pb-4">
        <div className="row align-items-end flex-md-row-reverse justify-content-end">
          <div className="col-12 buttons">
            <h1 className="head1 text-center head2mbl">Pixel Avatars</h1>
            <p className="text text-center textmbl1 px-1 mb-0">
              Each avatar is a unique creation, just like each one of you. 
              <br />
              <br />
              Hailing from diverse corners of the globe, they converge in our 
              peaceful village, a place where countless possibilities are born. 
              It's not uncommon to spot villagers donning a range of outfits and 
              toting both big and small items, reflecting their multifaceted 
              natures. Despite their differences, they share many common 
              qualities - they're cool, laid-back, and full of life.
              <br />
              <br />
              Unofficial estimates suggest that there may be up to four different 
              species of villagers. However, one of these groups is exceedingly 
              rare and their habits remain shrouded in mystery. We would love to 
              hear from you if you have any information about their whereabouts.
            </p>
            <div className="charactermbl">
              <img
                src={`./layers/skin/${skin}.png`}
                className="character_imgmbl"
              />
              {skin != "hidden" ? (
                <>
                  <img
                    src={`./layers/outfit/${outfits[outfit]}.png`}
                    className="character_imgmbl"
                  />
                  <img
                    src={`./layers/hairstyle/${
                      skin + "-" + hairstyles[hairstyle]
                    }.png`}
                    className="character_imgmbl"
                  />
                  <img
                    src={`./layers/expression/${expressions[expression]}.png`}
                    className="character_imgmbl"
                  />
                  <img
                    src={`./layers/weapon/${handhelds[handheld]}.png`}
                    className="character_imgmbl"
                  />
                  <img
                    src={`./layers/eyewear/${eyewears[eyewear]}.png`}
                    className="character_imgmbl"
                  />
                  <img
                    src={`./layers/headwear/${headwears[headwear]}.png`}
                    className="character_imgmbl"
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <img src={brickmbl} className="brickmbl px-4" />
            <div className="row mt-3">
              <div className="col-3">
                <div
                  className={`button_wrap_color button_wrap_colormbl ${
                    skin == "yellow" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("yellow")}
                >
                  <div className="button_color button_colormbl yellow"></div>
                  <small className="text">Neutral</small>
                </div>
              </div>
              <div className="col-3">
                <div
                  className={`button_wrap_color button_wrap_colormbl ${
                    skin == "tan" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("tan")}
                >
                  <div className="button_color button_colormbl tan"></div>
                  <small className="text">Tan</small>
                </div>
              </div>
              <div className="col-3">
                <div
                  className={`button_wrap_color button_wrap_colormbl ${
                    skin == "brown" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("brown")}
                >
                  <div className="button_color button_colormbl brown"></div>
                  <small className="text">Brown</small>
                </div>
              </div>
              <div className="col-3">
                <div
                  className={`button_wrap_color button_wrap_colormbl ${
                    skin == "hidden" ? "highlight" : ""
                  }`}
                  onClick={() => setSkin("hidden")}
                >
                  <img src={Q} className="w-100 buttonimgmbl" />
                </div>
              </div>
            </div>
            <div className="row icons_btns pt-3 pb-5">
              <div className="col-6">
                <div
                  className="button_wrap_color"
                  onClick={() =>
                    updateAvatar(expression, expressions, setexpressions)
                  }
                >
                  <img src={expression_btn} />
                  <span className="text">Expression</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(outfit, outfits, setOutfit)}
                >
                  <img src={outfit_btn} />
                  <span className="text">Outfit</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(handheld, handhelds, sethandheld)}
                >
                  <img src={weapon} />
                  <span className="text">Weapon</span>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(headwear, headwears, setheadwear)}
                >
                  <img src={headwear_btn} />
                  <span className="text">Headwear</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() => updateAvatar(eyewear, eyewears, seteyewear)}
                >
                  <img src={eyewear_btn} />
                  <span className="text">Eyewear</span>
                </div>
                <br />
                <div
                  className="button_wrap_color"
                  onClick={() =>
                    updateAvatar(hairstyle, hairstyles, setHairstyle)
                  }
                >
                  <img src={hairstyle_img} />
                  <span className="text">Hairstyle</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img id="cityhallmbl" src={entermbl} className="w-100" />
      <div id="enter" className="container-xl enterbg">
        <div className="row pb-5">
          <div className="col-12">
            <h1 className="head1 head2mbl text-center cityhall">
              Entering The <br />
              City Hall
            </h1>
            <p className="text text-center px-4 textmbl1">
              The magnificent tower standing in the village is the City Hall.
              Its door is firmly locked keeping people from entering. Behind the
              door, it’s a place where private events are held, secrets are kept
              and exclusive benefits and offerings are provided.
              <br />
              <br />
              Only the owners of DSUP avatars are authorized to access this
              mysterious space.
            </p>
            <div className="row pt-3 icons text-center">
              <div className="col-6 mb-4 px-4">
                <img src={ticket} className="img1 px-2" />
                <p className="text textmbl1 pe-xl-4">
                  Private events and parties
                </p>
              </div>
              <div className="col-6">
                <img src={couch} className="img2 px-2" />
                <p className="text textmbl1">
                  Voting rights in decentralisation
                </p>
              </div>
              <div className="col-6">
                <img src={scroll} className="img3 px-2" />
                <p className="text textmbl1">
                  Ownership and rights of commercial use
                </p>
              </div>
              <div className="col-6">
                <img src={chest} className="img4 px-2" />
                <p className="text textmbl1">
                  More joys and fun to be discovered
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span
        id="blueprintmbl"
        className="d-block"
        style={{ height: "30px", backgroundColor: "#07121a" }}
      ></span>
      <div
        id="slides"
        className="container-fluid p-0 slidesmbl p-relative"
        // onWheel={handleWheel}
      >
        <div
          style={{
            position: "absolute",
            top: "33.3vh",
            width: "280px",
            height: "4px",
            minHeight: "4px",
            zIndex: "10",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          className="mx-auto lines row"
        >
          <div className="col-3 px-2">
            <div
              className={`line ${slide == 0 ? "active" : ""}`}
              onClick={() => {
                setslide(0);
                sliderRef.current.slickGoTo(0);
              }}
            >
              <span></span>
            </div>
          </div>
          <div className="col-3 px-2">
            <div
              className={`line ${slide == 1 ? "active" : ""}`}
              onClick={() => {
                setslide(1);
                sliderRef.current.slickGoTo(1);
              }}
            >
              <span></span>
            </div>
          </div>
          <div className="col-3 px-2">
            <div
              className={`line ${slide == 2 ? "active" : ""}`}
              onClick={() => {
                setslide(2);
                sliderRef.current.slickGoTo(2);
              }}
            >
              <span></span>
            </div>
          </div>
          <div className="col-3 px-2">
            <div
              className={`line ${slide == 3 ? "active" : ""}`}
              onClick={() => {
                setslide(3);
                sliderRef.current.slickGoTo(3);
              }}
            >
              <span></span>
            </div>
          </div>
        </div>
        <Slider
          ref={sliderRef}
          {...settings}
          //  onSwipe={(d) => handleswipe(d)}
          onSwipe={(e) =>
            document
              .getElementById("blueprintmbl")
              .scrollIntoView({ behavior: "smooth", block: "start" })
          }
        >
          <div id="blueprint" className="container-fluid blueprintbg">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <div className="">
                    <h1 className="head1 head2mbl">Blueprint</h1>
                    <p className="text textmbl1 px-5">
                      Through building up a tunnel connecting to the digital and
                      physical worlds, we create and provide groundbreaking
                      goods and services to prosper the community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="downside" className="container-fluid">
            <img src={downsidembl} className="w-100 blueprintimg" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-11 col-md-5">
                  <h1 className="head1 head2mbl mb-2 pt-3">Downside</h1>
                  <p className="text textmbl1 mb-4 pb-2">
                    As the virtual part of DSUP, which is also the place where
                    our village is located, the boundaries of this world are our
                    imagination. Continuous search of and adventure to profound
                    areas allow us to acquire scarce resources for our
                    community.
                  </p>

                  <div className="accordion" id="accordionDownside">
                    <div className="accordion-item border-0 my-4">
                      <h2
                        className="accordion-header text"
                        id="headingOneDownside"
                      >
                        <button
                          className="btn btn-theme text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneDownside"
                          aria-expanded="false"
                          aria-controls="collapseOneDownside"
                        >
                          “All for one”
                        </button>
                      </h2>
                      <div
                        id="collapseOneDownside"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOneDownside"
                        data-bs-parent="#accordionDownside"
                      >
                        <div className="accordion-body text  textmbl1 px-2">
                          A game that is composed of games in diversified
                          genres. It provides a highly versatile environment
                          that will take an ordinary game to the next level. It
                          also incorporates the concept of decentralization.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0">
                      <h2
                        className="accordion-header text"
                        id="headingTwoDownside"
                      >
                        <button
                          className="btn btn-theme text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwoDownside"
                          aria-expanded="false"
                          aria-controls="collapseTwoDownside"
                        >
                          Tokenization
                        </button>
                      </h2>
                      <div
                        id="collapseTwoDownside"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwoDownside"
                        data-bs-parent="#accordionDownside"
                      >
                        <div className="accordion-body text  textmbl1 px-2">
                          A crypto token that will enhance the ecosystem and
                          provide more utilities to the community. Stay tuned
                          for that!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tunnel" className="container-fluid">
            <img src={tunnelbgmbl} className="w-100 blueprintimg" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-11 col-md-5">
                  <h1 className="head1 head2mbl mb-2 pt-3">The Tunnel</h1>
                  <p className="text textmbl1 mb-4 pb-2">
                    This is the core part of DSUP. The place where the magic
                    happens. Don’t be awed by any explosion sounds, as they’re
                    us breaking the rules and challenges, hewing out for your
                    tomorrow. The work here is strictly confidential, but we’ll
                    try to let you learn a bit.
                  </p>

                  <div className="accordion" id="accordionTunnel">
                    <div className="accordion-item border-0 my-4">
                      <h2
                        className="accordion-header text"
                        id="headingOneTunnel"
                      >
                        <button
                          className="btn btn-theme text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneDownside"
                          aria-expanded="false"
                          aria-controls="collapseOneDownside"
                        >
                          Web 3.0 Game
                        </button>
                      </h2>
                      <div
                        id="collapseOneDownside"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOneDownside"
                        data-bs-parent="#accordionTunnel"
                      >
                        <div className="accordion-body text px-2 textmbl1">
                          Making good use of the concept of Web 3.0 and our
                          avatar NFTs, the gaming experience will be advanced
                          with a deep use of the connection and interaction with
                          real life.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="upside" className="container-fluid">
            <img src={upsidembl2} className="w-100 blueprintimg" />
            <div className="container">
              <div className="row justify-content-center pb-5">
                <div className="col-11 col-md-5">
                  <h1 className="head1 head2mbl mb-2 pt-3">Upside</h1>
                  <p className="text textmbl1 mb-4 pb-2">
                    The physical part is equally essential as the digital part.
                    Among the numerous tasks we take up in this world, we’re
                    driven by one goal - to benefit our community. It’s more
                    than just building the brand and nourishing the community.
                    Actual and tangible benefits are on the way. We promise,
                    they’re unprecedented.
                  </p>

                  <div className="accordion" id="accordionUpside">
                    <div className="accordion-item border-0 my-4">
                      <h2
                        className="accordion-header text"
                        id="headingOneUpside"
                      >
                        <button
                          className="btn btn-theme text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneUpside"
                          aria-expanded="false"
                          aria-controls="collapseOneUpside"
                        >
                          Real-life events{" "}
                        </button>
                      </h2>
                      <div
                        id="collapseOneUpside"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOneUpside"
                        data-bs-parent="#accordionUpside"
                      >
                        <div className="accordion-body text px-2 textmbl1">
                          Sell-out party, meet-ups, parties, keynotes, exhibits
                          and more allow us to say “SUP” to each other in
                          person. Other greetings are welcome. Just be creative
                          and dope!
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0 my-4">
                      <h2
                        className="accordion-header text"
                        id="headingTwoUpside"
                      >
                        <button
                          className="btn btn-theme text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwoUpside"
                          aria-expanded="false"
                          aria-controls="collapseTwoUpside"
                        >
                          Merchandise
                        </button>
                      </h2>
                      <div
                        id="collapseTwoUpside"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwoUpdsde"
                        data-bs-parent="#accordionUpside"
                      >
                        <div className="accordion-body text textmbl1 px-2">
                          Exclusive merchs, from large pieces to handy items,
                          are for those who love physical possessions and
                          showcase your pride as one of the villagers.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0">
                      <h2
                        className="accordion-header text"
                        id="headingThreeUpside"
                      >
                        <button
                          className="btn btn-theme text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThreeUpside"
                          aria-expanded="false"
                          aria-controls="collapseThreeUpside"
                        >
                          Collabs & Partnership
                        </button>
                      </h2>
                      <div
                        id="collapseThreeUpside"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThreeUpside"
                        data-bs-parent="#accordionUpside"
                      >
                        <div className="accordion-body text textmbl1 px-2">
                          Collaborating with like-minded brands, be they from
                          the Downside or the Upside, creates synergies giving
                          you unparalleled experiences.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        {/* <div className="arrows">
          {left ? (
            <img
              src={arrow}
              className="arrow left"
              onClick={() => sliderRef.current.slickPrev()}
            />
          ) : (
            ""
          )}
          {right ? (
            <img
              src={arrowright}
              className="arrow right"
              onClick={() => sliderRef.current.slickNext()}
            />
          ) : (
            ""
          )}
        </div> */}
      </div>

      <div id="team" className="container-fluid teambg p-0 pt-5">
        <div className="container-xl">
          <div className="row full justify-content-center align-items-end pb-5">
            <div className="col-lg-6 col-xl-5 pb-0 pe-xl-5 p-lg-0">
              <h1 className="head1 text-center head2mbl">Team</h1>
              <p className="text textmbl1 mb-3 text-center">
                Core members with different areas of expertise have decided to
                pick up the pickaxe and are now the constructors for building up
                the Bridge.
              </p>
              <div className="row pt-5">
                <div className="col-3 px-0">
                  <div
                    className={`teamboxmbl mb-4 ${
                      avatarBig.name == "Dron" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: dronbigmbl,
                        name: "Dron",
                        title: "Playmaker",
                        description:
                          "Lazy af, can tell by the name. Been traveling between the technology and finance sectors. Sprinkling the precise mix of these two magic powders for the best potion.",
                      })
                    }
                  >
                    <img
                      src={dranmbl}
                      className="w-100"
                      style={{ marginLeft: "-10px" }}
                    />
                    <h1 className="head2teammbl">
                      <small>Dron</small>
                    </h1>
                  </div>
                </div>
                <div className="col-3 px-0">
                  <div
                    className={`teamboxmbl mb-4 ${
                      avatarBig.name == "Vil. Z" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: vilbigmbl,
                        name: "Vil. Z",
                        title: "Marketing",
                        description:
                          "Seasoned in e-commerce. Got a disliked hobby of burning guap in media buying.",
                      })
                    }
                  >
                    <img src={vilmbl} className="w-100" />
                    <h1 className="head2teammbl">
                      <small>Vil. Z</small>
                    </h1>
                  </div>
                </div>
                <div className="col-3 px-0">
                  <div
                    className={`teamboxmbl mb-4 ${
                      avatarBig.name == "Ax" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: axbigmbl,
                        name: "Ax",
                        title: "Engineer",
                        description:
                          "Ex-google software engineer. Ax the rot and smash the “reboot”. Got nothing to do with cherry trees.",
                      })
                    }
                  >
                    <img
                      src={axmbl}
                      className="w-100"
                      style={{ marginLeft: "-10px" }}
                    />
                    <h1 className="head2teammbl">
                      <small>Ax</small>
                    </h1>
                  </div>
                </div>
                <div className="col-3 px-0">
                  <div
                    className={`teamboxmbl mb-4 ${
                      avatarBig.name == "Fishmonger" ? "focused" : ""
                    }`}
                    onClick={() =>
                      setAvatarBig({
                        image: fishbigmbl,
                        name: "Fishmonger",
                        title: "Community",
                        description:
                          "“Don’t follow your dreams, follow me.” “Two for one.” He isn’t fishy. He’s trustworthy.",
                      })
                    }
                  >
                    <img src={fishmbl} className="w-100" />
                    <h1 className="head2teammbl">
                      <small>Fishmonger</small>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 p-0 pt-3 mt-4">
              <div className="boxmbl p-3 pb-2 text-center">
                <img src={avatarBig.image} className="dronbigmbl" />
                <h1 className="head1 head2mbl mb-2">{avatarBig.name}</h1>
                <p className="text borders py-2 px-5 mb-2 d-inline-block">
                  {avatarBig.title}
                </p>
                <p className="text textmbl1">{avatarBig.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="faq" className="container faqbg">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <h1 className="head1 text-center head2mbl">FAQ</h1>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header text" id="headingOne">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Where should I start off?{" "}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    A great way to start is to immerse yourself in our community 
                    (Discord is opening soon)
                    , where you can meet villagers coming from around the world.
                    Chilling around and chatting with them under the moon with a
                    warm breeze is what you wanna do. <br />
                    <br /> Nevertheless, we will never DM you nor ask for your
                    credentials. Every DM you receive is a scam. DON’T TRUST.
                    VERIFY.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading5">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="true"
                    aria-controls="collapse5"
                  >
                    What’s the total supply of DSUP NFTs?{" "}
                  </button>
                </h2>
                <div
                  id="collapse5"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading5"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    “Great oaks from little acorns grow.” Things are built bit
                    by bit. It’s also one of the reasons why DSUP NFTs are in
                    pixels. Inferentially, there’ll be 10,101 in total.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading7">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse7"
                    aria-expanded="true"
                    aria-controls="collapse7"
                  >
                    Which blockchain are the DSUP Avatar NFTs on?
                  </button>
                </h2>
                <div
                  id="collapse7"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading7"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    They are on the Ethereum chain. ERC721A protocol is used to
                    reduce gas fees.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading6">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse6"
                    aria-expanded="true"
                    aria-controls="collapse6"
                  >
                    What’s the roadmap?{" "}
                  </button>
                </h2>
                <div
                  id="collapse6"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading6"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    We’re agile and adaptive to changes. Thus, a phased roadmap
                    deprived us of creativity and flexibility in doing the right
                    and great things. Ideas for bridging the virtual and
                    physical worlds form the Blueprint. With this in mind, DSUP
                    will grow with optimal outcomes.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading2">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse2"
                    aria-expanded="true"
                    aria-controls="collapse2"
                  >
                    When’s the mint?
                  </button>
                </h2>
                <div
                  id="collapse2"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading2"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    Stay tuned! <br />
                    Follow us on <a href="https://twitter.com/DSUPOfficial" target="_blank">Twitter
                    </a>{" "}
                    and join our community (Discord is opening soon)
                     to keep posted.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading3">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="true"
                    aria-controls="collapse3"
                  >
                    How’ll the mint be?{" "}
                  </button>
                </h2>
                <div
                  id="collapse3"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading3"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    We haven’t decided yet. We mean, shall it be decided by us?
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header text" id="heading4">
                  <button
                    className="accordion-button text-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="true"
                    aria-controls="collapse4"
                  >
                    How do I get whitelisted?{" "}
                  </button>
                </h2>
                <div
                  id="collapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body text">
                    We like to reward those who participate and contribute to
                    building and growing the community. The details will be
                    announced via our Twitter and Discord. Follow and join,
                    don’t miss it. Stay active! Whitelisting is still open!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="footer" className="container footerbg">
        <div className="row py-5 justify-content-center align-items-center">
          <div className="col-12">
            <div className="text foothead text-center py-5">
              Join the village and enjoy the sunrise.
            </div>
          </div>
        </div>
      </div> */}
      <div id="footer2" className="container-fluid footer2bg">
        <div className="row pt-5 justify-content-center align-items-center">
          <div className="col-12 text-center">
            {/* <img src={logo} className="footlogo my-5" />
            <br />
            <h5 className="head1 mid mt-3 mb-5">Follow Us</h5> */}
            {/* <a href="https://www.instagram.com/dsupofficial/" target="_blank">
              <img src={insta} className="px-1" />
            </a>
            <a href="https://twitter.com/DSUPOfficial/" target="_blank">
              <img src={twitter} className="px-1" />
            </a>
            <a href="https://discord.gg/ZgqJu8UnsZ" target="_blank">
              <img src={discord} className="px-1" />
            </a> */}
          </div>
        </div>
        <div className="text-center pt-5 container border">
          {/* <img src={footavatar} /> */}
        </div>
        <div className="container">
          <div className="row pt-4 pb-2">
            <div className="col-sm-6 text-md-right text-center">
              <p className="text">
                <a href="/privacy-policy" className="text-white textmbl1">
                  Privacy Policy
                </a>
                <a href="/terms-of-use" className="ps-3 text-white textmbl1">
                  Terms of Use
                </a>
              </p>
            </div>
            <div className="col-sm-6 text-center text-md-left">
              <p className="text-white textmbl1" style={{ lineHeight: "30px" }}>
                © 2022 Seer Valley Inc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
