import { useState } from "react";
import logo from "../images/logo.png";

function Privacy() {
  const [hide, sethide] = useState(false);

  return (
    <>
      {window.innerWidth > 768 ? (
        <nav
          className={`px-4 navbar navmbl navbar-dark navbar-expand-md bg-transparent ${
            hide ? "nav-up" : ""
          }`}
        >
          <div className="container-fluid p-0">
            <a className="navbar-brand" href="/#">
              <img src={logo} style={{ width: "65px" }} />
            </a>
            <button
              className="navbar-toggler collapsed position-relative"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              id="toggle"
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                className="menubar"
              ></div>
              <ul className="navbar-nav navbar-navmbl ms-auto pt-3 align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="/#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#avatars">
                    Avatars
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#enter-cityhall">
                    City Hall
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#blueprint">
                    Blueprint
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#team">
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#faq">
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/DSUPOfficial/" target="_blank">
                    <i
                      className="fab fa-twitter ms-auto px-lg-3 px-1 px-md-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                  <a
                    href="https://www.instagram.com/dsupofficial/"
                    target="_blank"
                  >
                    <i
                      className="fab fa-instagram ms-auto px-lg-3 px-1 px-md-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                  <a href="https://discord.gg/ZgqJu8UnsZ/" target="_blank">
                    <i
                      className="fab fa-discord ms-auto px-lg-3 px-1 px-md-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <nav
          className={`px-4 navbar navmbl navbar-dark navbar-expand-md bg-transparent ${
            hide ? "nav-up" : ""
          }`}
        >
          <div className="container-fluid p-0">
            <a className="navbar-brand" href="/">
              <img src={logo} style={{ width: "65px" }} />
            </a>
            <button
              className="navbar-toggler collapsed position-relative"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              id="toggle"
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
            {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="cross">

                </span>
                <span className="navbar-toggler-icon"></span>
              </button> */}
            <div
              className="collapse navbar-collapse"
              id="navbarNav"
              // style={{ backgroundColor: "#182030bf" }}
            >
              <div
                style={
                  {
                    // position: "fixed",
                    // width: "100vw",
                    // height: "100vh",
                    // left: "0",
                    // top: "0",
                    // zIndex: "0",
                    // backgroundColor: "#182030e6",
                  }
                }
                // onClick={() => {
                //   document
                //     .getElementById("navbarNav")
                //     .classList.toggle("show");
                //   document
                //     .getElementById("toggle")
                //     .classList.toggle("collapsed");
                // }}

                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                className="menubar"
              ></div>
              <ul
                className="navbar-nav navbar-navmbl ms-auto pt-3"
                // style={{ position: "absolute" }}
              >
                <li className="nav-item">
                  <a className="nav-link" href="/#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#avatars">
                    Avatars
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#enter-cityhall">
                    City Hall
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#blueprint">
                    Blueprint
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#team">
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#faq">
                    FAQ
                  </a>
                </li>
                <hr className="my-0 mx-2 bg-white" style={{ opacity: 1 }} />
                <li className="nav-item iconmbl">
                  <a
                    href="https://twitter.com/DSUPOfficial/"
                    target="_blank"
                    className="nav-link d-flex justify-content-space-between align-items-center"
                  >
                    Twitter
                    {/* <img src={twitter} className="px-1" /> */}
                    <i
                      className="fab fa-twitter ms-auto d-block"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </a>
                </li>
                <li className="nav-item iconmbl">
                  <a
                    href="https://www.instagram.com/dsupofficial/"
                    target="_blank"
                    className="nav-link d-flex justify-content-space-between align-items-center"
                  >
                    Instagram
                    {/* <img src={insta} className="px-1" /> */}
                    <i
                      className="fab fa-instagram ms-auto d-block"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </a>
                </li>
                <li className="nav-item iconmbl">
                  <a
                    href="https://discord.gg/ZgqJu8UnsZ/"
                    target="_blank"
                    className="nav-link d-flex justify-content-space-between align-items-center"
                  >
                    Discord
                    {/* <img src={discord} className="px-1" /> */}
                    <i
                      className="fab fa-discord ms-auto d-block"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 p-5">
            <h1 class="head1 headingD">Privacy Policy</h1>
            <p className="text">
              Seer Valley, Inc (“Seer Valley”, “we”, “us”, or ”our”) provides
              you access to, use of, and interaction with DSUP (a platform
              created and owned by us) and our websites (the “Sites”), including
              any content, functionality, smart contracts and services offered
              on or through DSUP and Sites (collectively, the “Services”). We
              collect personal information through:
              <br />
              - the Sites;
              <br />
              - HTML-formatted email messages that we send to you that link to
              this Privacy Policy or other communications with you; and
              <br />
              - Offline business interactions you have with us.
              <br />
              <br />
              This Privacy Policy explains what personal information we collect,
              how we use and share that information, and your choices concerning
              our information practices. This Privacy Policy is incorporated
              into and forms part of our Terms of Use. Capitalized terms not
              defined in this Privacy Policy have the meaning given those terms
              in the Terms of Use.
              <br />
              <br />
              Before using the Services or submitting any personal information
              to Seer Valley, please review this Privacy Policy carefully and
              contact us if you have any questions. By using the Service, you
              agree to the practices described in this Privacy Policy. If you do
              not agree to this Privacy Policy, please do not access the Sites
              or otherwise use the Services.
            </p>
            <h1 className=" head2">1. PERSONAL INFORMATION COLLECTION</h1>
            <p className="text">
              “Personal Information” is information that identifies you as an
              individual or relates to an identifiable individual. We collect
              the following categories of Personal Information from you:
              <br />
              <br />
              <li>
                Identification Information: your name, email address, and
                shipping address.
              </li>
              <br />
              <li>
                Wallet Information: cryptocurrency wallet address, including
                related data used to detect the holdings of cryptocurrency,
                non-fungible tokens and digital assets.
              </li>
              <br />
              <li>
                Social Media Information: information, which may include your
                Personal Information, social media handles and followers list,
                that you provide or make available to us based on your settings
                through interaction with us on social media platforms like
                Instagram, Facebook, Twitter, and LinkedIn (“Social Media
                Platforms”).
              </li>
              <br />
              <li>
                Community Information: information relating to your
                participation in our community, including the events you attend
                and any information you share through our community platform
                like Discord.
              </li>
              <br />
              <li>
                Communication Information: information collected when you
                contact us with questions or concerns and when you voluntarily
                respond to questionnaires, surveys or requests for market
                research seeking your opinion and feedback.
              </li>
              <br />
              <li>
                In-game Activity Information: information collected when you
                interact within our game, either online or offline, such as
                quests and games completed, transactions of non-fungible tokens
                or digital assets.
              </li>
              <br />
              <li>
                Internet Activity Information: When you visit, use, and interact
                with the Service, the following information may be created and
                automatically logged into our systems:
              </li>
              <br />
              <li>
                {" "}
                Device Information: The manufacturer and model, operating
                system, IP address, and unique identifiers of the device, as
                well as the browser you use to access the Sites or Services.
              </li>
              <br />
              <li>
                {" "}
                Usage Information: Information about how you use our Services,
                such as the types of content that you view or engage with, the
                features you use, the actions you take, and the time, frequency,
                and duration of your activities.
              </li>
              <br />
              <li>
                {" "}
                Email Open/Click Information: Pixels may be used in our email
                campaigns to allow us to collect your email and IP address as
                well as the date and time you open an email or click on any
                links in the email.
              </li>
              <br />
              <p className="text">
                Internet Activity Information may be collected with the use of
                the following technologies:
                <b>Cookies.</b> They are pieces of information stored directly
                on your device to uniquely identify your browser or to store
                information or settings in the browser to help you navigate
                between pages efficiently, remember your preferences and enable
                functionality. We and our service providers use the information
                for security purposes, to facilitate navigation, to display
                information more effectively, and to personalize your
                experience. We also gather statistical information about the use
                of the Services or Sites in order to continually improve their
                design and functionality, understand how they are used, and
                assist us with resolving questions regarding them. Cookies
                further allow us to select which of our advertisements or offers
                are most likely to appeal to you and display them while you are
                on the Services. We may also use cookies or other technologies
                in online advertising to track responses to our ads.
                <br />
                <br />
                <b>Local storage technologies.</b> They provide
                cookie-equivalent functionality but can store larger amounts of
                data, including on your device outside of your browser in
                connection with specific applications.
                <br />
                <br />
                <b>Web beacons.</b> They are also known as pixel tags or clear
                GIFs, may be used to, among other things, track the actions of
                users of the Services and Sites (including email recipients),
                measure the success of our marketing campaigns, and compile
                statistics about usage of the Services and response rates.
                <br />
                <br />
                <b>Analytics.</b> We may use third-party analytics service
                providers which use cookies and similar technologies to collect
                and analyze information about the use of the Services or Sites
                and report on activities and trends. These analytics services
                may also collect information regarding the use of other
                websites, apps and online resources.
                <br />
                <br />
                We need to collect Personal Information in order to provide the
                requested Services to you. If you do not provide the information
                requested, we may not be able to provide the Services. If you
                disclose any Personal Information relating to other people to us
                or to our service providers in connection with the Services, you
                represent that you have the authority to do so and to permit us
                to use the information in accordance with this Privacy Policy.
              </p>
              <h1 className="head2">2. PERSONAL INFORMATION USE</h1>
              <p className="text">
                Personal Information is used for the following purposes:
                <br />
                <br />
                Service Delivery, including to:
                <li>
                  Provide, operate, maintain, and secure the Services and the
                  Sites;
                </li>
                <li>
                  Create, maintain, and authenticate your registered account;
                </li>
                <li>
                  Provide you with related benefits, special promotions, or
                  customer service;
                </li>
                <li>Administer your transactions; and</li>
                <li>Organize events.</li>
                Communication with You, including to:
                <li>
                  to send you updates about administrative matters such as
                  changes to our terms or policies; and
                </li>
                <li>
                  provide user support, and respond to your requests, questions
                  and feedback.
                </li>
                Service Improvement, including to:
                <li>
                  understand, analyze or predict your interests and preference;
                </li>
                <li>
                  improve, enhance, repair or modify the Services or the Sites;
                </li>
                <li>develop new products and services;</li>
                <li>personalize your experience;</li>
                <li>
                  create and derive insights from de-identified and aggregated
                  information;
                </li>
                <li>
                  determine the effectiveness of our promotional campaigns.
                </li>
                Marketing and Advertising, including to:
                <li>
                  send you marketing communications as permitted by law,
                  including, but not limited to, notifying you of special
                  promotions, offers, events, information about our services,
                  new products and other news about us by email and other means;
                  and
                </li>
                <li>
                  facilitate social sharing functionality that you choose to
                  use.
                </li>
                <li>
                  Audit our compliance with legal and contractual requirements
                  and internal policies; and
                </li>
                Compliance and Protection, including to:
                <li>
                  comply with applicable laws, lawful requests, and legal
                  process, such as to respond to subpoenas or requests from
                  government authorities;
                </li>
                <li>
                  protect our, your or others' rights, privacy, safety or
                  property (including by making and defending legal claims);
                </li>
                <li>
                  audit our compliance with legal and contractual requirements
                  and internal policies;
                </li>
                <li>
                  debug, identify and repair errors that impair the intended
                  functionality of the Services or Sites; and
                </li>
                <li>
                  prevent, identify, investigate and deter fraudulent, harmful,
                  unauthorized, unethical or illegal activity, including
                  cyberattacks and identity theft.
                </li>
              </p>
            </p>
            <h1 className="head2">3. PERSONAL INFORMATION SHARING</h1>
            <p className="text">
              We may share your Personal Information without further notice to
              you, unless required by the law, with the following categories of
              third parties:
              <br />
              <br />
              <li>
                <b>Third Party Service Providers</b>
                <br />
                These can include providers of services such as website hosting,
                data analysis, fraud prevention, information technology and
                related infrastructure provision, customer service or related
                benefits, email delivery, payment processing, advertising and
                marketing, auditing, and other services.
              </li>
              <li>
                <b>Professional Advisors</b>
                <br />
                We may share personal information with our professional advisors
                such as lawyers and accountants, where doing so is necessary to
                facilitate the services they render to us.
              </li>
              <li>
                <b>Government authorities and Regulators</b>
                <br />
                While we do not volunteer your personal information to
                government authorities or regulators, we may disclose your
                personal information where required to do so for the Compliance
                and Protection purposes described above.
              </li>
              <li>
                Counterparties in Business Transfers
                <br />
                If we are involved in a merger, acquisition, financing,
                reorganization, bankruptcy, receivership, dissolution, sale of
                all or a portion of our assets, or transition of service to
                another provider (collectively a “Transaction”), your personal
                information may be shared in the diligence process with
                counterparties and others assisting with the Transaction and
                transferred to a successor or affiliate as part of or following
                that Transaction along with other assets.
              </li>
            </p>
            <h1 className="head2">4. CHILDREN</h1>
            <p className="text">
              Our Services and Sites are not directed to children who are under
              the age of 16. Seer Valley does not knowingly collect personal
              information from children under the age of 16. If you believe that
              a child under the age of 16 provided personal information to Seer
              Valley, please contact us and we will delete that information.
            </p>
            <h1 className="head2">5. LINKS TO OTHER WEBSITES</h1>
            <p className="text">
              Our Services or Sites may contain links to other websites not
              operated or controlled by Seer Valley, including social media
              services (“Third Party Sites”). The information that you share
              with Third Party Sites will be governed by the specific privacy
              policies and terms of service of the Third Party Sites and not by
              this Privacy Policy. By providing these links we do not imply that
              we endorse or have reviewed these sites. Please contact the Third
              Party Sites directly for information on their privacy practices
              and policies.
            </p>
            <h1 className="head2">6. SECURITY</h1>
            <p className="text">
              We seek to use reasonable organizational, technical and
              administrative measures to protect Personal Information within our
              organization. Unfortunately, no data transmission or storage
              system can be guaranteed to be 100% secure. You use the Services
              and Sites at your own risk.
            </p>
            <h1 className="head2">
              7. PERSONAL INFORMATION PROCESSING IN THE U.S.
            </h1>
            <p className="text">
              Seer Valley is headquartered in the United States. By using our
              Services or Sites, you understand and acknowledge that your
              personal information will be transferred from your location to our
              facilities and servers in the United States, where data protection
              laws may be less protective than those in your jurisdiction.
            </p>
            <h1 className="head2">8. YOUR CHOICES</h1>
            <p className="text">
              <b>Update Personal Information.</b>
              You can contact us and request any updates or corrections needed
              to keep your personal information accurate, current, and complete.
            </p>
            <p className="text">
              <b>Opt Out of Marketing Communications.</b>
              You may opt out of marketing-related communications by following
              the opt out or unsubscribe instructions contained in any marketing
              communication we send you. Please note, however, that you may
              continue to receive communications as described in the
              Communication with You section after opting out of marketing
              communications.
            </p>
            <h1 className="head2">9. RETENTION PERIOD</h1>
            <p className="text">
              We retain Personal Information for as long as needed or permitted
              in light of the purpose(s) for which it was obtained and
              consistent with applicable law.
              <br />
              <br />
              The criteria used to determine our retention periods include:
              <li>
                The length of time we have an ongoing relationship with you and
                provide the Services to you (for example, for as long as you
                have an account with us or keep using the Services);
              </li>
              <li>
                Whether there is a legal obligation to which we are subject (for
                example, certain laws require us to keep records of your
                transactions for a certain period of time before we can delete
                them); or
              </li>
              <li>
                Whether retention is advisable in light of our legal position
                (such as in regard to applicable statutes of limitations,
                litigation or regulatory investigations).
              </li>
            </p>
            <h1 className="head2">10. CHANGES TO THE PRIVACY POLICY</h1>
            <p className="text">
              We may change this Privacy Policy at any time. For changes to this
              Privacy Policy, we will post an updated version on this page,
              unless another type of notice is required by applicable law. By
              continuing to use our Services or Sites or providing us with
              personal information after we have posted an updated Privacy
              Policy, or notified you by other means if applicable, you consent
              to the revised Privacy Policy and the practices described in it.
            </p>
            <h1 className="head2">11. CONTACT US</h1>
            <p className="text">
              If you have any questions about our Privacy Policy or information
              practices, please feel free to contact us at our designated
              request address: privacy@seervalley.com
            </p>{" "}
          </div>
        </div>
      </div>
      <div id="footer2" className="container-fluid footer2bg">
        <div className="row pt-5 justify-content-center align-items-center">
          <div className="col-12 text-center">
            {/* <img src={logo} className="footlogo my-5" />
            <br />
            <h5 className="head1 mid my-3">Follow Us</h5> */}
            {/* <a href="https://www.instagram.com/dsupofficial/" target="_blank">
              <img src={insta} className="px-1" />
            </a>
            <a href="https://twitter.com/DSUPOfficial/" target="_blank">
              <img src={twitter} className="px-1" />
            </a>
            <a href="https://discord.gg/ZgqJu8UnsZ/" target="_blank">
              <img src={discord} className="px-1" />
            </a> */}
          </div>
        </div>
        <div className="text-center pt-5 container-lg border">
          {/* <img src={footavatar} /> */}
        </div>
        <div className="container-lg">
          <div className="row pt-4 pb-2">
            <div className="col-sm-6 text-center text-md-left">
              <p className="text" style={{ fontSize: "14px" }}>
                © 2022 Seer Valley Inc.
              </p>
            </div>
            <div className="col-sm-6 text-md-right text-center">
              <p className="text">
                <a
                  href="/privacy-policy"
                  className="text"
                  style={{ fontSize: "14px" }}
                >
                  Privacy Policy
                </a>
                <a
                  href="/terms-of-use"
                  className="ps-3 text"
                  style={{ fontSize: "14px" }}
                >
                  Terms of Use
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
